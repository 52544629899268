import "@styles/pages/login.scss";

import "@scripts/pinia.ts";
import "@scripts/app.ts";
import '@scripts/enquiry-form.ts';

import Swiper from 'swiper';
import 'swiper/css/bundle';

class LoginInit {
    tabs: NodeListOf<Element>;
    tabContents: NodeListOf<Element>;
    signUp: HTMLElement;
    signIn: HTMLElement;

    resetPasswordForm: HTMLFormElement;
    emailInput:HTMLInputElement;
    questionAnswerInput:HTMLInputElement;
    questionAnswerFakeInput:HTMLInputElement;
    passwordInput:HTMLInputElement;
    confirmPasswordInput:HTMLInputElement;

    constructor() {
       this.getDOM();
       this.tabsInit();   
       //new TabSlide();
       this.resetPasswordInitForm();
    }

    getDOM(){
        this.tabs = document.querySelectorAll(".tabs li");
        this.signUp = document.querySelector("#sign-up-trigger");
        this.signIn = document.querySelector("#sign-in-trigger");
        this.tabContents = document.querySelectorAll(".tab-content");

        this.resetPasswordForm = document.getElementById("resetPasswordForm") as HTMLFormElement;
        this.emailInput = document.getElementById("Email") as HTMLInputElement;
        this.questionAnswerInput = document.getElementById("QuestionAnswer") as HTMLInputElement;
        this.questionAnswerFakeInput = document.getElementById("QuestionAnswerFake") as HTMLInputElement;
        this.passwordInput = document.getElementById("Password") as HTMLInputElement;
        this.confirmPasswordInput = document.getElementById("ConfirmPassword") as HTMLInputElement;
    }

    tabsInit() {
        this.tabs.forEach((tab, i) => {
            tab.addEventListener("click", () => {
                this.tabs.forEach(t => t.classList.remove("active"));
                this.tabContents.forEach(t => t.classList.remove("active"));
                this.tabs[i].classList.add("active");
                this.tabContents[i].classList.add("active");               
            });
        });

        if (this.signIn) {
            this.signIn.addEventListener("click", () => {
                this.tabs.forEach(t => t.classList.remove("active"));
                this.tabContents.forEach(t => t.classList.remove("active"));
                this.tabs[0].classList.add("active");
                this.tabContents[0].classList.add("active");     
            });
        }
        
        if (this.signUp) {
            this.signUp.addEventListener("click", () => {
                this.tabs.forEach(t => t.classList.remove("active"));
                this.tabContents.forEach(t => t.classList.remove("active"));
                this.tabs[1].classList.add("active");
                this.tabContents[1].classList.add("active");     
            });
        }     
        
        new Swiper(".tabs-swiper", { 
            slidesPerView: "auto",  
            spaceBetween: 10, 
            freeMode:true,           
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                500: {
                    slidesPerView: "auto",  
                    spaceBetween: 10,                     
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                },
                800: {
                    slidesPerView: "auto",  
                    spaceBetween: 10,                               
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                }
            },         
        }); 
    }   

    // KUO-3339 / KUO-3337 : Really horrible hacks to try and stop chrome autofilling / remembering the wrong fields
    resetPasswordInitForm() {
        if (!this.resetPasswordForm) {
            return;
        }

        const loginInit = this;
        this.resetPasswordForm.addEventListener("submit", function (event) {
            if (loginInit.emailInput) {
                loginInit.emailInput.removeAttribute("readonly");
                loginInit.emailInput.removeAttribute("disabled");
            }
            if (loginInit.questionAnswerInput && loginInit.questionAnswerFakeInput) {
                loginInit.questionAnswerFakeInput.value = loginInit.questionAnswerInput.value;
            }
            if (loginInit.questionAnswerInput) {
                loginInit.questionAnswerInput.setAttribute("type", "hidden");
            }
            if (loginInit.questionAnswerFakeInput) {
                loginInit.questionAnswerFakeInput.setAttribute("type", "text");
                loginInit.questionAnswerFakeInput.setAttribute("readonly", "");
                loginInit.questionAnswerFakeInput.setAttribute("disabled", "disabled");
            }

            if (loginInit.passwordInput) {
                loginInit.passwordInput.removeAttribute("readonly");
            }
        });
    }
    stopChromeAutofillFocus() {
        if (!this || typeof (<any>this).hasAttribute !== "function") {
            return;
        }
        const input:HTMLInputElement = (<any>this);
        if (input.hasAttribute("stopChromeAutofill")) {
            return;
        }

        input.setAttribute("stopChromeAutofill", "")
        try {
            if (input.hasAttribute("readonly")) {
                input.removeAttribute("readonly");
        
                // fix for mobile safari to show virtual keyboard
                input.blur();
                input.focus();
            }
        } finally {
            input.removeAttribute("stopChromeAutofill")
        }

    }
    stopChromeAutofillBlur() {
        if (!this || typeof (<any>this).hasAttribute !== "function") {
            return;
        }
        const input:HTMLInputElement = (<any>this);
        if (input.hasAttribute("stopChromeAutofill")) {
            return;
        }

        input.setAttribute("stopChromeAutofill", "")
        try {
            if (!input.hasAttribute("readonly")) {
                input.setAttribute("readonly", "")
            }
        } finally {
            input.removeAttribute("stopChromeAutofill")
        }
    }
};

document.addEventListener("DOMContentLoaded", function() {
    new LoginInit();
});